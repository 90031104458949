import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/primary/SEO'
import Listing from '../components/secondary/Listing'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import Article from '../templates/article'
import Container from '../components/primary/Container'
import Layout from '../components/primary/Layout'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>  
    </Container>
  </Layout>
)

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    article: Article,
  },
})
/*
export const listQuery = graphql`
  query {
    
  }
`
*/